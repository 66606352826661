// User Action Types
export const HANDLE_USER = 'HANDLE_USER'
export const SET_USERS = 'SET_USERS'

// request states for advertiser module
export const FETCH_ADVERTISER_SUCCESS = 'FETCH_ADVERTISER_SUCCESS'
export const FETCH_ADVERTISER_ERROR = 'FETCH_ADVERTISER_ERROR'

// Types for Token management
export const SET_TOKEN = 'SET_TOKEN'
// request states for role module
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS'
export const FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR'
export const FETCH_DELETE_ROLES_SUCCESS = 'FETCH_DELETE_ROLES_SUCCESS'
export const FETCH_DELETE_ROLES_ERROR = 'FETCH_DELETE_ROLES_ERROR'
export const FETCH_CREATE_ROLES = 'FETCH_CREATE_ROLES'
export const FETCH_CREATE_ROLES_ERROR = 'FETCH_CREATE_ROLES_ERROR'
export const FETCH_ASIGN_PERMISSION_BY_ROLE_ON_MODULE =
  'FETCH_ASIGN_PERMISSION_BY_ROLE_ON_MODULE'
export const FETCH_ASIGN_PERMISSION_BY_ROLE_ON_MODULE_ERROR =
  'FETCH_ASIGN_PERMISSION_BY_ROLE_ON_MODULE_ERROR'
export const FETCH_PERMISSION_BY_ROLE_ON_MODULE =
  'FETCH_PERMISSION_BY_ROLE_ON_MODULE'
export const FETCH_PERMISSION_BY_ROLE_ON_MODULE_ERROR =
  'FETCH_PERMISSION_BY_ROLE_ON_MODULE_ERROR'
export const FETCH_EDIT_PERMISSION_BY_ROLE_ON_MODULE =
  'FETCH_EDIT_PERMISSION_BY_ROLE_ON_MODULE'
export const FETCH_EDIT_PERMISSION_BY_ROLE_ON_MODULE_ERROR =
  'FETCH_EDIT_PERMISSION_BY_ROLE_ON_MODULE_ERROR'
export const SET_PERMISSIONS_BY_ROLE = 'SET_PERMISSIONS_BY_ROLE'

// Types needed for the pricing module
export const FETCH_PRICING_SUCCESS = 'FETCH_PRICING_SUCCESS'
export const FETCH_CONTENT_PRICING_SUCCESS = 'FETCH_CONTENT_PRICING_SUCCESS'
export const FETCH_PRICING_ERROR = 'FETCH_PRICING_ERROR'
export const FETCH_FORMATS_BY_DISPLAY_SUCCESS =
  'FETCH_FORMATS_BY_DISPLAY_SUCCESS'
export const FETCH_FORMATS_BY_DISPLAY_ERROR = 'FETCH_FORMATS_BY_DISPLAY_ERROR'
export const FETCH_PORTALS_SUCCESS = 'FETCH_PORTALS_SUCCESS'
export const FETCH_PORTALS_ERROR = 'FETCH_PORTALS_ERROR'
export const FETCH_NEW_PRICING_SUCCESS = 'FETCH_NEW_PRICING_SUCCESS'
export const FETCH_NEW_CONTENT_PRICING_SUCCESS =
  'FETCH_NEW_CONTENT_RICING_SUCCESS'
export const FETCH_NEW_PRICING_ERROR = 'FETCH_NEW_PRICING_ERROR'
export const FETCH_DELETE_PRICING_SUCCESS = 'FETCH_DELETE_PRICING_SUCCESS'
export const FETCH_DELETE_CONTENT_PRICING_SUCCESS =
  'FETCH_DELETE_CONTENT_PRICING_SUCCESS'
export const FETCH_DELETE_PRICING_ERROR = 'FETCH_DELETE_PRICING_ERROR'
export const FETCH_EDIT_PRICING_SUCCESS = 'FETCH_EDIT_PRICING_SUCCESS'
export const FETCH_EDIT_CONTENT_PRICING_SUCCESS =
  'FETCH_EDIT_CONTENT_PRICING_SUCCESS'
export const FETCH_EDIT_PRICING_ERROR = 'FETCH_EDIT_PRICING_ERROR'

// Types needed for the campaign module
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS'
export const FETCH_CAMPAIGNS_ERROR = 'FETCH_CAMPAIGNS_ERROR'
export const FETCH_ASSIGN_USER_SUCCESS = 'FETCH_ASSIGN_USER_SUCCESS'
export const FETCH_ASSIGN_USER_ERROR = 'FETCH_ASSIGN_USER_ERROR'
export const FETCH_CANCEL_CAMPAIGN_SUCCESS = 'FETCH_CANCEL_CAMPAIGN_SUCCESS'
export const FETCH_CANCEL_CAMPAIGN_ERROR = 'FETCH_CANCEL_CAMPAIGN_ERROR'

// Formats types
export const SET_FORMATS = 'SET_FORMATS'
export const SET_FETCHING_FORMATS = 'SET_FETCHING_FORMATS'
export const SET_FORMAT_STATUS = 'SET_FORMAT_STATUS'

// Displays types
export const SET_DISPLAYS = 'SET_DISPLAYS'

// Digital properties types
export const SET_DIGITAL_PROPERTIES = 'SET_DIGITAL_PROPERTIES'

// Resources types
export const SET_RESOURCES = 'SET_RESOURCES'
export const SET_RESOURCE_STATUS = 'SET_RESOURCE_STATUS'
export const SET_RESOURCE_ASSET = 'SET_RESOURCE_ASSET'
export const SET_FETCHING_RESOURCES = 'SET_FETCHING_RESOURCES'

// Global messages types
export const FETCH_SET_GLOBAL_MESSAGE = 'FETCH_SET_GLOBAL_MESSAGE'

// loading type
export const FETCH_LOADING = 'FETCH_LOADING'

// Types for audiences
export const SET_SEGEMENTATION_SCOPES = 'SET_SEGEMENTATION_SCOPES'
export const SET_SEGMENTATION_TYPES = 'SET_SEGMENTATION_TYPES'
export const SET_SEGMENTS = 'SET_SEGMENTS'
export const SET_FETCHING_AUDIENCE = 'SET_FETCHING_AUDIENCE'
export const SET_MATCH_SCOPE = 'SET_MATCH_SCOPE'
export const SET_MATCH_STATUS = 'SET_MATCH_STATUS'
export const ADD_MATCH = 'ADD_MATCH'
