import axios from 'axios'
import { API_ENDPOINT } from '../../../constants/apiEndpoint'
import { AdvertisingTypeCode } from './constants'

/**
 * Get list of resource with their relationships info (advertising, campaign, account)
 * @returns {response} response axios
 */
export const fetchAllMaterials = () => {
  const url = `${API_ENDPOINT}/campaigns`
  return axios
    .get(url, {
      params: {
        filter: {
          where: { manualStatus: { neq: 0 } },
          include: [
            {
              relation: 'advertisements',
              scope: {
                include: [
                  {
                    relation: 'resources',
                    scope: { where: { enabled: true } },
                  },
                  { relation: 'advertisingType' },
                ],
              },
            },
            { relation: 'account' },
            {
              relation: 'adSponsoredContents',
              scope: {
                include: [
                  {
                    relation: 'adSponsoredContentPricingAdContents',
                    scope: {
                      include: [
                        {
                          relation: 'pricingAdContent',
                          scope: {
                            include: [
                              {
                                relation: 'advertisingTypeDigitalProperties',
                                scope: {
                                  include: [{ relation: 'advertisingType' }],
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    relation: 'resourcesContents',
                    scope: { where: { enabled: true } },
                  },
                ],
              },
            },
          ],
        },
      },
    })
    .then((r) => r.data)
}

/**
 * Update resource status with adittional observation
 * @param {num} id
 * @param {num} typeId
 * @param {num} status
 * @param {string} observation
 * @returns {response} response axios
 */
export const updateResourceStatus = async (id, typeId, status, observation) => {
  let response
  const body = {
    status: status,
    observation: observation,
  }
  if (typeId === AdvertisingTypeCode.Content) {
    response = await axios.patch(
      `${API_ENDPOINT}/resources_content/${id}/change-state`,
      body
    )
  } else
    response = await axios.patch(
      `${API_ENDPOINT}/resource/${id}/change-state`,
      body
    )
  return response
}

/**
 * Replace resource for an advertising
 * @param {num} id
 * @param {obj} file
 * @returns
 */
export const replaceResource = async (id, file) => {
  const formData = new FormData()
  formData.append('file', file)
  const response = await axios.patch(
    `${API_ENDPOINT}/advertising/${id}/replace-resource`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  )
  return response
}

/**
 * Upload report file
 * @param {num} id
 * @param {obj} file
 * @returns
 */
export const uploadReport = async (id, typeId, file) => {
  const type =
    typeId === AdvertisingTypeCode.Content
      ? 'ad-sponsored-contents'
      : 'advertising'
  const formData = new FormData()
  formData.append('file', file)
  const response = await axios.patch(
    `${API_ENDPOINT}/${type}/${id}/report`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  )
  return response
}

export const updateSmartReport = async (advertisingId, urlSmart) => {
  const body = {
    smartTrackingLink: urlSmart,
  }
  let response = await axios.patch(
    `${API_ENDPOINT}/advertising/${advertisingId}`,
    body
  )
  return response
}

export const updateDataStudioReport = async (
  typeId,
  advertisingId,
  urlReport
) => {
  const typeAdvertising =
    typeId === AdvertisingTypeCode.Content
      ? 'ad-sponsored-contents'
      : 'advertising'
  const body = {
    report: urlReport,
  }
  const response = await axios.patch(
    `${API_ENDPOINT}/${typeAdvertising}/${advertisingId}/report-studio`,
    body
  )
  return response
}
