export const ICONS = {
  arrowDown: {
    width: 11,
    height: 6,
    viewBox: '0 0 11 6',
    paths: ['M5.5,6.5 0,1.5 1.4,0 5.5,3.9 10,0 11.3,1.5'],
  },
  arrowLeft: {
    width: 6.5,
    height: 11.3,
    viewBox: '0 0 6.5 11.3',
    paths: ['M5,11.3 0,5.5 5,0 6.5,1.4 2.7,5.5 6.5,10'],
  },
  arrowRight: {
    width: 6.5,
    height: 11.3,
    viewBox: '0 0 6.5 11.3',
    paths: ['M1.5,11.3 0,10 3.9,5.8 0,1.3 1.5,0 6.5,5.9'],
  },
  briefCase: {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    paths: [
      'M11.4,10.9c0,0.3-0.3,0.6-0.6,0.6H7.4c-0.3,0-0.6-0.3-0.6-0.6V9.1H0v5.1C0,15.2,0.8,16,1.7,16h14.9c0.9,0,1.7-0.8,1.7-1.7V9.1h-6.9V10.9z M16.6,3.4h-2.9V1.7C13.7,0.8,12.9,0,12,0H6.3C5.3,0,4.6,0.8,4.6,1.7v1.7H1.7C0.8,3.5,0,4.2,0,5.1V8h18.3V5.1C18.3,4.2,17.5,3.5,16.6,3.4L16.6,3.4z M11.4,3.4H6.9V2.3h4.6L11.4,3.4z',
    ],
  },
  calendar: {
    width: 15,
    height: 18,
    viewBox: '0 0 15 18',
    paths: [
      'M0,16.3C0,17.2,0.8,18,1.7,18h12.4c0.9,0,1.7-0.8,1.7-1.7V6.7H0V16.3z M11.3,9.4c0-0.2,0.2-0.4,0.4-0.4h1.4c0.2,0,0.4,0.2,0.4,0.4v1.4c0,0.2-0.2,0.4-0.4,0.4h-1.4c-0.2,0-0.4-0.2-0.4-0.4V9.4z M11.3,13.9c0-0.2,0.2-0.4,0.4-0.4h1.4c0.2,0,0.4,0.2,0.4,0.4v1.4c0,0.2-0.2,0.4-0.4,0.4h-1.4c-0.2,0-0.4-0.2-0.4-0.4V13.9z M6.8,9.4C6.8,9.2,6.9,9,7.2,9h1.4C8.8,9,9,9.2,9,9.4v1.4c0,0.2-0.2,0.4-0.4,0.4H7.2c-0.2,0-0.4-0.2-0.4-0.4V9.4z M6.8,13.9c0-0.2,0.2-0.4,0.4-0.4h1.4c0.2,0,0.4,0.2,0.4,0.4v1.4c0,0.2-0.2,0.4-0.4,0.4H7.2c-0.2,0-0.4-0.2-0.4-0.4V13.9z M2.3,9.4C2.3,9.2,2.4,9,2.7,9h1.4c0.2,0,0.4,0.2,0.4,0.4v1.4c0,0.2-0.2,0.4-0.4,0.4H2.7c-0.2,0-0.4-0.2-0.4-0.4V9.4z M2.3,13.9c0-0.2,0.2-0.4,0.4-0.4h1.4c0.2,0,0.4,0.2,0.4,0.4v1.4c0,0.2-0.2,0.4-0.4,0.4H2.7c-0.2,0-0.4-0.2-0.4-0.4V13.9z M14.1,2.2h-1.7V0.6c0-0.3-0.3-0.6-0.6-0.6h-1.1c-0.3,0-0.6,0.3-0.6,0.6v1.7H5.6V0.6C5.6,0.3,5.4,0,5.1,0H3.9C3.6,0,3.4,0.3,3.4,0.6v1.7H1.7C0.8,2.2,0,3,0,3.9v1.7h15.8V3.9C15.7,3,15,2.3,14.1,2.2z',
    ],
  },
  clipBoard: {
    width: 13,
    height: 18,
    viewBox: '0 0 13 18',
    paths: [
      'M11.8,2.2H9C9,1,8,0,6.8,0S4.5,1,4.5,2.2H1.7C0.8,2.2,0,3,0,3.9c0,0,0,0,0,0v12.4C0,17.2,0.8,18,1.7,18h10.1c0.9,0,1.7-0.8,1.7-1.7V3.9C13.5,3,12.7,2.3,11.8,2.2z M3.4,14.9c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8l0,0C4.2,14.5,3.8,14.9,3.4,14.9C3.4,14.9,3.4,14.9,3.4,14.9z M3.4,11.5c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8l0,0C4.2,11.2,3.8,11.5,3.4,11.5C3.4,11.5,3.4,11.5,3.4,11.5z M3.4,8.2c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8l0,0C4.2,7.8,3.8,8.2,3.4,8.2C3.4,8.2,3.4,8.2,3.4,8.2z M6.8,1.4c0.5,0,0.8,0.4,0.8,0.8S7.2,3.1,6.8,3.1S5.9,2.7,5.9,2.2l0,0C5.9,1.8,6.3,1.4,6.8,1.4C6.7,1.4,6.7,1.4,6.8,1.4z M11.3,14.3c0,0.2-0.1,0.3-0.3,0.3H5.9c-0.2,0-0.3-0.1-0.3-0.3v-0.6c0-0.2,0.1-0.3,0.3-0.3H11c0.2,0,0.3,0.1,0.3,0.3V14.3z M11.3,11c0,0.2-0.1,0.3-0.3,0.3H5.9c-0.2,0-0.3-0.1-0.3-0.3v-0.6c0-0.2,0.1-0.3,0.3-0.3H11c0.2,0,0.3,0.1,0.3,0.3V11z M11.3,7.6c0,0.2-0.1,0.3-0.3,0.3H5.9c-0.2,0-0.3-0.1-0.3-0.3V7c0-0.2,0.1-0.3,0.3-0.3H11c0.2,0,0.3,0.1,0.3,0.3V7.6z',
    ],
  },
  dollarSign: {
    width: 9.6,
    height: 17,
    viewBox: '0 0 9.6 17',
    paths: [
      'M6.9,7.7L3.4,6.7C2.8,6.5,2.5,6,2.7,5.5c0.1-0.4,0.5-0.7,0.9-0.7h2.2c0.4,0,0.8,0.1,1.1,0.3c0.2,0.1,0.5,0.1,0.6-0.1l1.2-1.1C9,3.7,9,3.4,8.8,3.2c0,0,0,0-0.1-0.1c-0.8-0.6-1.8-1-2.9-1V0.5C5.8,0.2,5.6,0,5.3,0H4.2C4,0,3.7,0.2,3.7,0.5v1.6H3.6C1.6,2.1,0,3.8,0,5.8C0,5.9,0,6,0,6.1c0.2,1.5,1.3,2.8,2.8,3.2l3.4,1C6.7,10.5,7,11,6.9,11.5c-0.1,0.4-0.5,0.7-0.9,0.7H3.7c-0.4,0-0.8-0.1-1.1-0.3c-0.2-0.1-0.5-0.1-0.6,0.1l-1.2,1.1c-0.2,0.2-0.2,0.6,0,0.8c0,0,0,0,0.1,0.1c0.8,0.6,1.8,1,2.9,1v1.6C3.7,16.8,4,17,4.2,17h1.1c0.3,0,0.5-0.2,0.5-0.5v-1.6c1.6,0,3-1,3.5-2.4c0.7-1.9-0.3-4-2.2-4.6C7.1,7.8,7,7.8,6.9,7.7L6.9,7.7z',
    ],
  },
  edit: {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    paths: [
      'M14.2,2.9l3.2,3.2c0.1,0.1,0.1,0.4,0,0.5l-7.7,7.7l-3.3,0.4c-0.4,0-0.7-0.2-0.8-0.6c0-0.1,0-0.1,0-0.2L6,10.6l7.7-7.7C13.8,2.8,14,2.8,14.2,2.9L14.2,2.9z M19.9,2.1l-1.7-1.7c-0.5-0.5-1.4-0.5-1.9,0L15,1.6c-0.1,0.1-0.1,0.4,0,0.5l3.2,3.2c0.1,0.1,0.4,0.1,0.5,0l1.2-1.2C20.4,3.5,20.4,2.7,19.9,2.1L19.9,2.1z M13.5,12.2v3.6H2.3V4.5h8.1c0.1,0,0.2,0,0.3-0.1L12,3c0.2-0.2,0.2-0.4,0-0.6c-0.1-0.1-0.2-0.1-0.3-0.1h-10C0.8,2.2,0,3,0,3.9v12.4C0,17.2,0.8,18,1.7,18h12.4c0.9,0,1.7-0.8,1.7-1.7v-5.5c0-0.2-0.2-0.4-0.4-0.4c-0.1,0-0.2,0-0.3,0.1l-1.4,1.4C13.5,11.9,13.5,12.1,13.5,12.2z',
    ],
  },
  history: {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    paths: [
      'M17,8.5c0,4.7-3.8,8.5-8.5,8.5c-1.9,0-3.8-0.7-5.4-1.9C2.8,14.8,2.8,14.3,3,14c0,0,0,0,0.1-0.1l0.4-0.4c0.3-0.3,0.8-0.3,1.1-0.1c2.7,2.2,6.7,1.7,8.9-1s1.7-6.7-1-8.9c-2.5-1.9-6-1.8-8.2,0.3l1.7,1.7c0.2,0.2,0.2,0.6,0,0.8C5.8,6.5,5.7,6.6,5.5,6.6h-5C0.2,6.6,0,6.3,0,6c0,0,0,0,0,0V1c0-0.3,0.2-0.5,0.5-0.5c0.1,0,0.3,0.1,0.4,0.2l1.7,1.7c3.4-3.2,8.8-3.1,12,0.3C16.2,4.2,17,6.3,17,8.5z M10.8,11.2l0.3-0.4c0.3-0.4,0.2-0.9-0.1-1.2L9.6,8.5V4.9c0-0.5-0.4-0.8-0.8-0.8H8.2c-0.5,0-0.8,0.4-0.8,0.8v4.6l2.2,1.7C10,11.6,10.5,11.5,10.8,11.2z',
    ],
  },
  images: {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    paths: [
      'M17.1,13.7v0.6c0,0.9-0.8,1.7-1.7,1.7c0,0,0,0,0,0H1.7C0.8,16,0,15.2,0,14.3c0,0,0,0,0,0V5.1c0-0.9,0.8-1.7,1.7-1.7c0,0,0,0,0,0h0.6v7.4c0,1.6,1.3,2.9,2.9,2.9L17.1,13.7z M20.6,10.9V1.7c0-0.9-0.8-1.7-1.7-1.7c0,0,0,0,0,0H5.1C4.2,0,3.4,0.8,3.4,1.7l0,0v9.1c0,0.9,0.8,1.7,1.7,1.7c0,0,0,0,0,0h13.7C19.8,12.6,20.6,11.8,20.6,10.9L20.6,10.9L20.6,10.9zM9.1,3.4c0,0.9-0.8,1.7-1.7,1.7S5.7,4.4,5.7,3.4c0-0.9,0.8-1.7,1.7-1.7S9.1,2.5,9.1,3.4L9.1,3.4z M5.7,8.6l2-2c0.2-0.2,0.4-0.2,0.6,0L9.7,8l4.8-4.8C14.7,3,15,3,15.2,3.2l3.1,3.1v4H5.7V8.6z',
    ],
  },
  add: {
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
    paths: ['M10,4 6,4 6,0 4,0 4,4 0,4 0,6 4,6 4,10 6,10 6,6 10,6'],
  },
  trash: {
    width: 15,
    height: 18,
    viewBox: '0 0 15 18',
    paths: [
      'M1.1,16.3c0,0.9,0.8,1.7,1.7,1.7h10.1c0.9,0,1.7-0.8,1.7-1.7l0,0V4.5H1.1V16.3z M10.7,7.3c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v7.9c0,0.3-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6V7.3z M7.3,7.3c0-0.3,0.3-0.6,0.6-0.5c0.3,0,0.5,0.2,0.5,0.5v7.9c0,0.3-0.3,0.6-0.6,0.5c-0.3,0-0.5-0.2-0.5-0.5V7.3z M3.9,7.3C4,7,4.2,6.8,4.5,6.8C4.8,6.8,5,7,5.1,7.3v7.9c0,0.3-0.3,0.6-0.6,0.5c-0.3,0-0.5-0.2-0.5-0.5V7.3z M15.2,1.1H11l-0.3-0.7C10.5,0.2,10.2,0,9.9,0h-4C5.5,0,5.3,0.2,5.1,0.5L4.8,1.1H0.6C0.3,1.1,0,1.4,0,1.7c0,0,0,0,0,0v1.1c0,0.3,0.3,0.6,0.6,0.6l0,0h14.6c0.3,0,0.6-0.3,0.6-0.6c0,0,0,0,0,0V1.7C15.7,1.4,15.5,1.1,15.2,1.1C15.2,1.1,15.2,1.1,15.2,1.1z',
    ],
  },
  users: {
    width: 23,
    height: 16,
    viewBox: '0 0 23 16',
    paths: [
      'M3.4,6.9c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3s-2.3,1-2.3,2.3l0,0C1.1,5.8,2.2,6.9,3.4,6.9z M19.4,6.9c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3l0,0C17.1,5.8,18.2,6.9,19.4,6.9z M20.6,8h-2.3c-0.6,0-1.2,0.2-1.6,0.7c1.5,0.8,2.5,2.3,2.7,3.9h2.4c0.6,0,1.1-0.5,1.1-1.1c0,0,0,0,0,0v-1.1C22.9,9,21.8,8,20.6,8z M11.4,8c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S9.2,8,11.4,8C11.4,8,11.4,8,11.4,8L11.4,8z M14.2,9.1h-0.3c-1.5,0.8-3.4,0.8-4.9,0H8.7c-2.3,0-4.1,1.8-4.1,4.1v1c0,0.9,0.8,1.7,1.7,1.7c0,0,0,0,0,0h10.3c0.9,0,1.7-0.8,1.7-1.7v-1C18.3,11,16.4,9.1,14.2,9.1z M6.2,8.7C5.8,8.2,5.2,8,4.6,8H2.3C1,8,0,9,0,10.3v1.1c0,0.6,0.5,1.1,1.1,1.1c0,0,0,0,0,0h2.4C3.7,10.9,4.7,9.5,6.2,8.7L6.2,8.7z',
    ],
  },
  checkbox: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    paths: ['M0.1,0.1v19.8h19.8V0.1H0.1z M1,19V1h18v18H1z'],
  },
  checkboxTrue: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    paths: [
      'M0.1,0.1v19.8h19.8V0.1H0.1z M19,19H1V1h18V19z',
      'M17.1,6 14.4,3.7 8.1,11.3 5.1,8.9 2.9,11.6 8.4,16.3',
    ],
  },
  settings: {
    width: 19,
    height: 20,
    viewBox: '0 0 19 20',
    paths: [
      'M18.9,12.4l-1.7-1c0.2-0.9,0.2-1.9,0-2.8l1.7-1c0.2-0.1,0.3-0.3,0.2-0.6c-0.4-1.4-1.2-2.7-2.2-3.8C16.8,3,16.5,3,16.3,3.1l-1.7,1c-0.7-0.6-1.5-1.1-2.4-1.4v-2c0-0.2-0.2-0.4-0.4-0.5c-1.4-0.3-3-0.3-4.4,0C7.2,0.3,7,0.5,7,0.7v2C6.1,3,5.3,3.5,4.5,4.1l-1.7-1C2.6,3,2.4,3,2.2,3.2C1.2,4.3,0.5,5.6,0,7C0,7.2,0,7.5,0.2,7.6l1.7,1c-0.2,0.9-0.2,1.9,0,2.8l-1.7,1C0.1,12.5,0,12.8,0,13c0.4,1.4,1.2,2.7,2.2,3.8C2.4,17,2.6,17,2.8,16.9l1.7-1C5.3,16.5,6.1,17,7,17.3v2c0,0.2,0.2,0.4,0.4,0.5c1.4,0.3,3,0.3,4.4,0c0.2,0,0.4-0.2,0.4-0.5v-2c0.9-0.3,1.7-0.8,2.5-1.4l1.7,1c0.2,0.1,0.4,0.1,0.6-0.1c1-1.1,1.8-2.4,2.2-3.8C19.2,12.8,19.1,12.5,18.9,12.4z M9.6,13.2c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2c0,0,0,0,0,0C12.8,11.8,11.3,13.2,9.6,13.2L9.6,13.2z',
    ],
  },
  signOut: {
    width: 19,
    height: 15,
    viewBox: '0 0 19 15',
    paths: [
      'M19.4,8.2l-6.6,6.6c-0.4,0.4-1,0.4-1.3,0c-0.2-0.2-0.3-0.4-0.3-0.7v-3.7H5.9C5.4,10.3,5,9.9,5,9.4c0,0,0,0,0,0V5.6c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0,0h5.3V0.9c0-0.5,0.4-0.9,0.9-0.9c0.2,0,0.5,0.1,0.7,0.3l6.6,6.6C19.8,7.2,19.8,7.8,19.4,8.2zM7.5,14.5V13c0-0.3-0.2-0.5-0.5-0.5H3.7c-0.7,0-1.2-0.6-1.3-1.2c0,0,0,0,0,0V3.7c0-0.7,0.6-1.2,1.2-1.3c0,0,0,0,0,0H7c0.3,0,0.5-0.2,0.5-0.5V0.5C7.5,0.2,7.3,0,7,0H3.7C1.7,0,0,1.7,0,3.7v7.5C0,13.3,1.7,15,3.7,15H7C7.3,15,7.5,14.8,7.5,14.5z',
    ],
  },
  advertise: {
    width: 19,
    height: 16.9,
    viewBox: '0 0 19 16.9',
    paths: [
      'M19,7.9c0-0.8-0.4-1.4-1.1-1.8v-5c0-0.6-0.5-1.1-1.1-1.1c-0.2,0-0.5,0.1-0.7,0.2l-2.8,2.2c-1.4,1.1-3.2,1.7-5,1.7H2.1C1,4.2,0,5.2,0,6.3c0,0,0,0,0,0v3.2c0,1.2,0.9,2.1,2.1,2.1l0,0h1.1c0,0.4-0.1,0.7-0.1,1.1c0,1.3,0.3,2.5,0.8,3.7c0.2,0.4,0.5,0.6,0.9,0.6h2.5c0.6,0,1.1-0.5,1.1-1c0-0.2-0.1-0.4-0.2-0.6c-0.6-0.7-0.9-1.6-0.9-2.6c0-0.4,0.1-0.7,0.1-1.1h0.9c1.8,0,3.6,0.6,5,1.7l2.8,2.2c0.2,0.1,0.4,0.2,0.7,0.2c0.6,0,1.1-0.5,1.1-1.1v-5C18.6,9.4,19,8.7,19,7.9z M15.8,12.6l-1.1-0.9c-1.8-1.4-4-2.2-6.3-2.2V6.3c2.3,0,4.5-0.8,6.3-2.2l1.1-0.9V12.6z',
    ],
  },
  format: {
    width: 23.9,
    height: 10.3,
    viewBox: '0 0 23.9 10.3',
    paths: [
      'M17.7,1.1v2.2H6.3V1.1C6.3,0.5,5.8,0,5.1,0C4.8,0,4.6,0.1,4.4,0.3l-4,4c-0.4,0.4-0.4,1.1,0,1.6l4,4c0.4,0.4,1.2,0.4,1.6,0c0.2-0.2,0.3-0.5,0.3-0.8V7h11.4v2.2c0,0.6,0.5,1.1,1.1,1.1c0.3,0,0.6-0.1,0.8-0.3l4-4c0.4-0.4,0.4-1.1,0-1.6l-4-4c-0.4-0.4-1.2-0.4-1.6,0C17.8,0.5,17.7,0.8,17.7,1.1L17.7,1.1z',
    ],
  },
  close: {
    width: 20,
    height: 20,
    viewBox: ' 0 0 20 20',
    paths: [
      'M10,0.1c-5.5,0-9.9,4.4-9.9,9.9s4.4,9.9,9.9,9.9s9.9-4.4,9.9-9.9S15.5,0.1,10,0.1z M10,18.5c-4.7,0-8.5-3.8-8.5-8.5c0-4.7,3.8-8.5,8.5-8.5c4.7,0,8.5,3.8,8.5,8.5C18.5,14.7,14.7,18.5,10,18.5z',
      'M13.6,7.9 12.2,6.4 10.1,8.5 8,6.4 6.6,7.9 8.6,10 6.6,12 8,13.5 10.1,11.4 12.2,13.5 13.6,12 11.6,10 ',
    ],
  },
  check: {
    width: 20,
    height: 20,
    viewBox: ' 0 0 20 20',
    paths: [
      'M10,0.1c-5.5,0-9.9,4.4-9.9,9.9s4.4,9.9,9.9,9.9s9.9-4.4,9.9-9.9S15.5,0.1,10,0.1z M10,18.5c-4.7,0-8.5-3.8-8.5-8.5c0-4.7,3.8-8.5,8.5-8.5c4.7,0,8.5,3.8,8.5,8.5C18.5,14.7,14.7,18.5,10,18.5z',
      'M9.1,13.8 5.7,10.9 7.1,9.3 8.8,10.8 12.6,6.2 14.3,7.6',
    ],
  },
  approve: {
    width: 15,
    height: 13,
    viewBox: '0 0 12 10',
    paths: ['M4.7,10.7 0,6.7 1.9,4.4 4.4,6.5 9.8,0 12.1,1.9'],
  },
  upload: {
    width: 15,
    height: 15,
    viewBox: '0 0 18 18',
    paths: [
      'M10.4,13.5H7.6c-0.5,0-0.8-0.4-0.8-0.8c0,0,0,0,0,0V6.7H3.7C3.3,6.8,3,6.4,3,6.1C3,5.9,3,5.7,3.2,5.5l5.4-5.3c0.3-0.3,0.7-0.3,1,0l5.4,5.4c0.3,0.3,0.3,0.7,0,1c-0.1,0.1-0.3,0.2-0.5,0.2h-3.1v5.9C11.3,13.1,10.9,13.5,10.4,13.5C10.4,13.5,10.4,13.5,10.4,13.5z M18,13.2v3.9c0,0.5-0.4,0.8-0.8,0.8c0,0,0,0,0,0H0.8C0.4,18,0,17.6,0,17.2c0,0,0,0,0,0v-3.9c0-0.5,0.4-0.8,0.8-0.8c0,0,0,0,0,0h4.8v0.3c0,1.1,0.9,2,2,2c0,0,0,0,0,0h2.8c1.1,0,2-0.9,2-2v-0.3h4.8C17.6,12.4,18,12.7,18,13.2C18,13.2,18,13.2,18,13.2z M13.6,16.3c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7C13.3,17,13.6,16.7,13.6,16.3z M15.9,16.3c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7C15.6,17,15.9,16.7,15.9,16.3z',
    ],
  },
  download: {
    width: 15,
    height: 15,
    viewBox: '0 0 16 16',
    paths: [
      'M6.961,0H9.539a.772.772,0,0,1,.773.773V6.188h2.826a.643.643,0,0,1,.454,1.1l-4.9,4.9a.624.624,0,0,1-.88,0L2.9,7.286a.643.643,0,0,1,.454-1.1H6.188V.773A.772.772,0,0,1,6.961,0ZM16.5,12.117v3.609a.772.772,0,0,1-.773.773H.773A.772.772,0,0,1,0,15.727V12.117a.772.772,0,0,1,.773-.773H5.5L7.08,12.923a1.651,1.651,0,0,0,2.34,0L11,11.344h4.728A.772.772,0,0,1,16.5,12.117Zm-4,2.836a.645.645,0,1,0-.645.645A.646.646,0,0,0,12.5,14.953Zm2.063,0a.645.645,0,1,0-.645.645A.646.646,0,0,0,14.566,14.953Z',
    ],
  },
  see: {
    width: 22,
    height: 16,
    viewBox: '0 0 22 16',
    paths: [
      'm21.373936,7.583739c-1.961376,-3.826908 -5.844344,-6.416155 -10.290443,-6.416155s-8.330152,2.591056 -10.290443,6.416517a1.170026,1.170026 0 0 0 0,1.055736c1.961376,3.826908 5.844344,6.416155 10.290443,6.416155s8.330152,-2.591056 10.290443,-6.416517a1.170026,1.170026 0 0 0 0,-1.055736zm-10.290443,5.736202a5.208153,5.208153 0 1 1 5.208153,-5.208153a5.205621,5.205621 0 0 1 -5.208153,5.208153zm0,-8.680255a3.447146,3.447146 0 0 0 -0.915405,0.137076a1.730626,1.730626 0 0 1 -2.419621,2.419621a3.464145,3.464145 0 1 0 3.335026,-2.556697z',
    ],
  },
  desktop: {
    width: 15,
    height: 12,
    viewBox: '0 0 15 12',
    paths: [
      'M14.625,9.75H8.942a.739.739,0,0,1-.767.75H6.75a.8.8,0,0,1-.768-.75H.375A.376.376,0,0,0,0,10.125V10.5A1.5,1.5,0,0,0,1.5,12h12A1.5,1.5,0,0,0,15,10.5v-.375A.376.376,0,0,0,14.625,9.75ZM13.5,1.125A1.128,1.128,0,0,0,12.375,0H2.625A1.128,1.128,0,0,0,1.5,1.125V9h12ZM12,7.5H3v-6h9Z',
    ],
  },
  mobile: {
    width: 10,
    height: 15,
    viewBox: '0 0 10 15',
    paths: [
      'M7.969,0H1.406A1.407,1.407,0,0,0,0,1.406V13.594A1.407,1.407,0,0,0,1.406,15H7.969a1.407,1.407,0,0,0,1.406-1.406V1.406A1.407,1.407,0,0,0,7.969,0ZM4.688,14.063a.938.938,0,1,1,.938-.937A.936.936,0,0,1,4.688,14.063ZM7.969,10.9a.353.353,0,0,1-.352.352H1.758a.353.353,0,0,1-.352-.352V1.758a.353.353,0,0,1,.352-.352H7.617a.353.353,0,0,1,.352.352Z',
    ],
  },
  search: {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    paths: [
      'm13.96825,12.223078l-2.790405,-2.790405a0.67066,0.67066 0 0 0 -0.476053,-0.195653l-0.456174,0a5.792156,5.792156 0 0 0 1.231461,-3.581386a5.819359,5.819359 0 0 0 -5.820405,-5.820405a5.819359,5.819359 0 0 0 -5.820405,5.820405a5.819359,5.819359 0 0 0 5.820405,5.820405a5.792156,5.792156 0 0 0 3.582432,-1.231461l0,0.456174a0.671706,0.671706 0 0 0 0.195653,0.476053l2.790405,2.790405a0.668567,0.668567 0 0 0 0.948968,0l0.792027,-0.792027a0.674845,0.674845 0 0 0 0.002093,-0.952107zm-8.311577,-2.986057a3.579293,3.579293 0 0 1 -3.581386,-3.581386a3.579293,3.579293 0 0 1 3.581386,-3.581386a3.580339,3.580339 0 0 1 3.582432,3.581386a3.580339,3.580339 0 0 1 -3.582432,3.582432l0,-0.001046z',
    ],
  },
  error: {
    width: 13,
    height: 13,
    viewBox: '0 0 10 10',
    paths: [
      'M10.1,2.1 8,0 5.1,2.9 2.1,0 0,2.1 2.9,5.1 0,8 2.1,10.1 5.1,7.2 8,10.1 10.1,8 7.2,5.1',
    ],
  },
  audiencia: {
    width: 32,
    height: 15,
    viewBox: '0 0 32.032 15',
    paths: [
      'M3.2,0c1,0,1.9,0.8,1.9,1.9S4.3,3.8,3.2,3.8S1.4,2.9,1.4,1.9l0,0C1.4,0.8,2.2,0,3.2,0 M4.6,4.2H4.3c-0.7,0.3-1.5,0.3-2.1,0H1.8C1,4.2,0.4,4.8,0.4,5.6c0,0,0,0,0,0v4c0,0.4,0.3,0.7,0.7,0.7h0.5v4c0,0.4,0.3,0.7,0.7,0.7h1.9c0.4,0,0.7-0.3,0.7-0.7v-4h0.5C5.7,10.3,6,10,6,9.6v-4C6,4.9,5.4,4.2,4.6,4.2z',
      'M20.2,0c1,0,1.9,0.8,1.9,1.9s-0.8,1.9-1.9,1.9s-1.9-0.8-1.9-1.9l0,0C18.4,0.8,19.2,0,20.2,0 M21.6,4.2h-0.3c-0.7,0.3-1.5,0.3-2.1,0h-0.3c-0.8,0-1.4,0.6-1.4,1.4c0,0,0,0,0,0v4c0,0.4,0.3,0.7,0.7,0.7h0.5v4c0,0.4,0.3,0.7,0.7,0.7h1.9c0.4,0,0.7-0.3,0.7-0.7v-4h0.5c0.4,0,0.7-0.3,0.7-0.7v-4C23,4.9,22.4,4.2,21.6,4.2z',
      'M11.9,0c1,0,1.9,0.8,1.9,1.9S13,3.8,11.9,3.8s-1.9-0.8-1.9-1.9S10.9,0,11.9,0 M15.4,10.4L14,4.8c-0.1-0.3-0.4-0.5-0.7-0.5H13c-0.7,0.3-1.5,0.3-2.1,0h-0.3c-0.3,0-0.6,0.2-0.7,0.5l-1.4,5.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.1,0,0.1,0,0.2,0h1.6v3.1c0,0.4,0.3,0.7,0.7,0.7h0.9c0.4,0,0.7-0.3,0.7-0.7v-3.1h1.6c0.4,0,0.7-0.3,0.7-0.7C15.5,10.5,15.4,10.4,15.4,10.4z',
      'M28.9,0c1,0,1.9,0.8,1.9,1.9S30,3.8,28.9,3.8s-1.9-0.8-1.9-1.9S27.9,0,28.9,0 M32.4,10.4L31,4.8c-0.1-0.3-0.4-0.5-0.7-0.5H30c-0.7,0.3-1.5,0.3-2.1,0h-0.3c-0.3,0-0.6,0.2-0.7,0.5l-1.4,5.6c-0.1,0.4,0.1,0.8,0.5,0.9c0.1,0,0.1,0,0.2,0h1.6v3.1c0,0.4,0.3,0.7,0.7,0.7h0.9c0.4,0,0.7-0.3,0.7-0.7v-3.1h1.6c0.4,0,0.7-0.3,0.7-0.7C32.5,10.5,32.4,10.4,32.4,10.4z',
    ],
  },
  radio: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    paths: [
      'M10,19.8c-5.4,0-9.8-4.4-9.8-9.8c0-5.4,4.4-9.8,9.8-9.8c5.4,0,9.8,4.4,9.8,9.8C19.8,15.4,15.4,19.8,10,19.8z M10,1.2c-4.9,0-8.8,4-8.8,8.8c0,4.9,4,8.8,8.8,8.8c4.9,0,8.8-4,8.8-8.8C18.8,5.1,14.9,1.2,10,1.2z',
    ],
  },
  radioCheck: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    paths: [
      'M10,0.2c-5.4,0-9.8,4.4-9.8,9.8c0,5.4,4.4,9.8,9.8,9.8c5.4,0,9.8-4.4,9.8-9.8C19.8,4.6,15.4,0.2,10,0.2z M10,18.8c-4.9,0-8.8-4-8.8-8.8c0-4.9,4-8.8,8.8-8.8c4.9,0,8.8,4,8.8,8.8C18.8,14.9,14.9,18.8,10,18.8z',
      'M10,5c-2.7,0-5,2.2-5,5c0,2.7,2.2,5,5,5c2.7,0,5-2.2,5-5C15,7.3,12.7,5,10,5z',
    ],
  },
  bull: {
    width: 13.1,
    height: 19,
    viewBox: '0 0 13.1 19',
    paths: [
      'M6.5,3C4.6,3,3,4.6,3,6.5c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6c0-1.3,1.1-2.4,2.4-2.4c0.3,0,0.6-0.3,0.6-0.6C7.1,3.2,6.9,3,6.5,3L6.5,3z M3.6,17c0,0.1,0,0.2,0.1,0.3l0.9,1.4C4.7,18.9,4.9,19,5.1,19H8c0.2,0,0.4-0.1,0.5-0.3l0.9-1.4c0.1-0.1,0.1-0.2,0.1-0.3v-1.6H3.6L3.6,17z M6.5,0C2.9,0,0,2.9,0,6.5c0,1.6,0.6,3.1,1.6,4.3c0.9,1,1.5,2.2,1.9,3.4l0,0h1.8l0,0c0-0.2,0-0.4-0.1-0.5C4.8,12.2,4,10.8,3,9.7c-1.7-2-1.5-5,0.4-6.7s5-1.5,6.7,0.4c1.6,1.8,1.6,4.5,0,6.3c-1,1.2-1.8,2.6-2.3,4.1c-0.1,0.2-0.1,0.4-0.1,0.5l0,0h1.8l0,0c0.4-1.3,1.1-2.4,1.9-3.4c2.4-2.7,2.1-6.8-0.6-9.2C9.6,0.6,8.1,0,6.5,0z',
    ],
  },
  bullCheck: {
    width: 13.1,
    height: 19,
    viewBox: '0 0 13.1 19',
    paths: [
      'M3.6,16.9c0,0.2,0.1,0.5,0.2,0.7l0.6,1C4.6,18.8,5,19,5.4,19h2.3c0.4,0,0.8-0.2,1-0.5l0.6-1c0.1-0.2,0.2-0.4,0.2-0.7v-1.4H3.6L3.6,16.9z M0,6.5c0,1.6,0.6,3.1,1.6,4.3c0.8,1,1.5,2.2,1.9,3.4v0h5.9v0c0.4-1.2,1.1-2.4,1.9-3.4c2.4-2.7,2.1-6.8-0.6-9.2S4-0.5,1.6,2.2C0.6,3.4,0,4.9,0,6.5z M6.5,3.6c-1.6,0-3,1.3-3,3c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.6c0,0,0,0,0,0c0-2.3,1.9-4.2,4.2-4.2c0.3,0,0.6,0.2,0.6,0.6C7.2,3.3,6.9,3.5,6.5,3.6C6.6,3.6,6.5,3.6,6.5,3.6L6.5,3.6z',
    ],
  },
  restroom: {
    width: 20,
    height: 16.8,
    viewBox: '0 0 20 16.8',
    paths: [
      'M3.2,4.2c1.2,0,2.1-0.9,2.1-2.1S4.3,0,3.2,0C2,0,1.1,0.9,1.1,2.1c0,0,0,0,0,0C1.1,3.3,2,4.2,3.2,4.2zM15.8,4.2c1.2,0,2.1-0.9,2.1-2.1S17,0,15.8,0c-1.2,0-2.1,0.9-2.1,2.1c0,0,0,0,0,0C13.7,3.3,14.6,4.2,15.8,4.2z M20,11.7l-1.5-6.1c-0.1-0.4-0.5-0.8-1-0.8c-1,0.7-2.4,0.7-3.4,0c-0.5,0-0.9,0.4-1,0.8l-1.5,6.1c-0.1,0.4,0.2,0.9,0.6,1c0.1,0,0.1,0,0.2,0h1.8v3.4c0,0.4,0.4,0.8,0.8,0.8c0,0,0,0,0,0h1.6c0.4,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0v-3.4h1.8c0.4,0,0.8-0.3,0.8-0.8C20,11.8,20,11.7,20,11.7z M10,0H8.9C8.7,0,8.4,0.2,8.4,0.5v15.8c0,0.3,0.2,0.5,0.5,0.5H10c0.3,0,0.5-0.2,0.5-0.5V0.5C10.5,0.2,10.3,0,10,0z M4.9,4.8c-1,0.7-2.4,0.7-3.4,0C0.6,4.8,0,5.5,0,6.3v4.5c0,0.4,0.4,0.8,0.8,0.8c0,0,0,0,0,0h0.3v4.5c0,0.4,0.4,0.8,0.8,0.8c0,0,0,0,0,0h2.6c0.4,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0v-4.5h0.3c0.4,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0V6.3C6.3,5.5,5.7,4.8,4.9,4.8z',
    ],
  },
  birthdayCake: {
    width: 17.5,
    height: 20,
    viewBox: '0 0 17.5 20',
    paths: [
      'M17.5,15c-1.1,0-1.2-1.3-2.9-1.3S12.8,15,11.7,15s-1.2-1.3-2.9-1.3c-1.7,0-1.8,1.3-2.9,1.3c-1.1,0-1.2-1.3-2.9-1.3S1.1,15,0,15v-3.1c0-1,0.8-1.9,1.9-1.9l0,0h0.6V4.4H5V10h2.5V4.4H10V10h2.5V4.4H15V10h0.6c1,0,1.9,0.8,1.9,1.9l0,0V15z M17.5,20H0v-3.8c1.7,0,1.8-1.3,2.9-1.3s1.2,1.3,2.9,1.3c1.7,0,1.8-1.3,2.9-1.3c1.1,0,1.2,1.3,2.9,1.3s1.8-1.3,2.9-1.3c1.1,0,1.2,1.3,2.9,1.3V20z M3.8,3.8c-0.7,0-1.2-0.6-1.3-1.2c0,0,0,0,0,0c0-1.2,1.3-0.9,1.3-2.5C4.2,0,5,1.2,5,2.2S4.4,3.8,3.8,3.8z M8.8,3.8c-0.7,0-1.2-0.6-1.3-1.2c0,0,0,0,0,0c0-1.2,1.3-0.9,1.3-2.5C9.2,0,10,1.2,10,2.2S9.4,3.8,8.8,3.8zM13.8,3.8c-0.7,0-1.2-0.6-1.3-1.2c0,0,0,0,0,0c0-1.2,1.3-0.9,1.3-2.5C14.2,0,15,1.2,15,2.2S14.4,3.8,13.8,3.8z',
    ],
  },
  social: {
    width: 17.5,
    height: 20,
    viewBox: '0 0 17.5 20',
    paths: [
      'M9,4.6c1.3,0,2.3-1,2.3-2.3S10.3,0,9,0S6.7,1,6.7,2.3c0,0,0,0,0,0C6.7,3.5,7.7,4.6,9,4.6z M10.6,5.1h-0.3c-0.8,0.4-1.8,0.4-2.6,0H7.4C6.1,5.1,5,6.2,5,7.5c0,0,0,0,0,0v0.7c0,0.5,0.4,0.9,0.9,0.9c0,0,0,0,0,0h6.3c0.5,0,0.9-0.4,0.9-0.9l0,0V7.5C13,6.2,11.9,5.1,10.6,5.1L10.6,5.1L10.6,5.1z',
      'M14,15.6c1.3,0,2.3-1,2.3-2.3S15.3,11,14,11c-1.3,0-2.3,1-2.3,2.3c0,0,0,0,0,0C11.7,14.5,12.7,15.6,14,15.6zM15.6,16.1h-0.3c-0.8,0.4-1.8,0.4-2.6,0h-0.3c-1.3,0-2.4,1.1-2.4,2.4c0,0,0,0,0,0v0.7c0,0.5,0.4,0.9,0.9,0.9c0,0,0,0,0,0h6.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-0.7C18,17.2,16.9,16.1,15.6,16.1L15.6,16.1L15.6,16.1z',
      'M4,15.6c1.3,0,2.3-1,2.3-2.3S5.3,11,4,11c-1.3,0-2.3,1-2.3,2.3c0,0,0,0,0,0C1.7,14.5,2.7,15.6,4,15.6zM5.6,16.1H5.3c-0.8,0.4-1.8,0.4-2.6,0H2.4c-1.3,0-2.4,1.1-2.4,2.4c0,0,0,0,0,0v0.7c0,0.5,0.4,0.9,0.9,0.9c0,0,0,0,0,0h6.3c0.5,0,0.9-0.4,0.9-0.9l0,0v-0.7C8,17.2,6.9,16.1,5.6,16.1L5.6,16.1L5.6,16.1z',
    ],
  },
  report: {
    width: 11,
    height: 15,
    viewBox: '0 0 10 14',
    paths: [
      'M9.633,2.452,7.448.267A1.25,1.25,0,0,0,6.565-.1H1.25A1.254,1.254,0,0,0,0,1.153V11.986a1.25,1.25,0,0,0,1.25,1.25h7.5A1.25,1.25,0,0,0,10,11.986V3.337a1.256,1.256,0,0,0-.367-.885Zm-.984.784H6.667V1.254Zm-7.4,8.75V1.153H5.417V3.861a.624.624,0,0,0,.625.625H8.75v7.5Z',
    ],
  },
  file: {
    width: 12,
    height: 15,
    viewBox: '0 0 12 15',
    paths: [
      'M6.563,3.984V0H.7A.7.7,0,0,0,0,.7V14.3a.7.7,0,0,0,.7.7h9.844a.7.7,0,0,0,.7-.7V4.688H7.266A.705.705,0,0,1,6.563,3.984Zm1.91,6.328H6.563v2.344a.469.469,0,0,1-.469.469H5.156a.469.469,0,0,1-.469-.469V10.313H2.778a.469.469,0,0,1-.33-.8l2.825-2.8a.5.5,0,0,1,.7,0L8.8,9.511A.469.469,0,0,1,8.472,10.313Zm2.573-7.237L8.177.205A.7.7,0,0,0,7.679,0H7.5V3.75h3.75V3.571A.7.7,0,0,0,11.045,3.076Z',
    ],
  },
  approved: {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    paths: [
      'M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0Z',
      'M5.4,8.7 2.9,6.5 4.1,5 5.2,5.9 7.7,2.9 9.3,4.1',
    ],
  },
  eye: {
    width: 16,
    height: 11,
    viewBox: '0 0 16 11',
    paths: [
      'M8 0C4.86 0.03 2 1.81 0.59 4.62C0.47 4.86 0.47 5.14 0.59 5.38C2 8.19 4.86 9.98 8 10C11.14 9.97 14 8.19 15.41 5.38C15.53 5.14 15.53 4.86 15.41 4.62C14 1.81 11.14 0.02 8 0ZM4.25 5C4.25 2.93 5.93 1.25 8 1.25C10.07 1.25 11.75 2.93 11.75 5C11.75 7.07 10.07 8.75 8 8.75C5.93 8.75 4.25 7.07 4.25 5ZM7.34 2.6C7.75 3.15 7.64 3.93 7.09 4.34C6.65 4.67 6.04 4.67 5.6 4.34C5.24 5.67 6.02 7.04 7.35 7.4C8.68 7.76 10.05 6.98 10.41 5.65C10.77 4.32 9.99 2.95 8.66 2.59C8.44 2.53 8.22 2.5 8 2.5C7.78 2.5 7.56 2.54 7.34 2.6Z',
    ],
  },
  sync: {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    paths: [
      'M9.51 3.28C7.73 1.62 4.95 1.71 3.29 3.48C2.78 4.03 2.41 4.7 2.23 5.42C2.2 5.56 2.07 5.66 1.93 5.66C1.78 5.66 0.58 5.66 0.43 5.66C0.25 5.66 0.11 5.52 0.11 5.35C0.11 5.33 0.11 5.31 0.12 5.29C0.79 1.76 4.19 -0.55 7.71 0.12C8.95 0.35 10.08 0.93 10.99 1.8C11.08 1.71 11.83 0.96 11.93 0.86C12.17 0.62 12.57 0.62 12.82 0.86C12.93 0.98 13 1.14 13 1.31C13 1.66 13 4.47 13 4.82C13 5.17 12.72 5.45 12.37 5.45C12.37 5.45 12.37 5.45 12.37 5.45C12.02 5.45 9.21 5.45 8.86 5.45C8.51 5.45 8.23 5.17 8.23 4.82C8.23 4.66 8.29 4.5 8.41 4.38C8.63 4.16 9.29 3.5 9.51 3.28ZM4.14 7.55C4.49 7.55 4.77 7.83 4.77 8.18C4.77 8.34 4.71 8.5 4.59 8.62C4.48 8.73 3.6 9.61 3.49 9.72C5.27 11.38 8.05 11.29 9.71 9.52C10.22 8.97 10.59 8.3 10.77 7.58C10.81 7.44 10.93 7.34 11.08 7.34C11.23 7.34 12.43 7.34 12.58 7.34C12.75 7.34 12.89 7.48 12.89 7.65C12.89 7.67 12.89 7.69 12.89 7.71C12.22 11.24 8.81 13.55 5.29 12.89C4.06 12.65 2.92 12.07 2.01 11.2C1.92 11.29 1.17 12.04 1.07 12.14C0.83 12.38 0.43 12.38 0.18 12.14C0.07 12.02 0 11.86 0 11.69C0 11.34 0 8.53 0 8.18C0 7.83 0.28 7.55 0.63 7.55C0.63 7.55 3.79 7.55 4.14 7.55Z',
    ],
  },
  url: {
    width: 12,
    height: 15,
    viewBox: '0 0 12 15',
    paths: [
      'M9.806,14.906h-8.4A1.406,1.406,0,0,1,0,13.5V3.254A1.406,1.406,0,0,1,1.407,1.847H3.694a1.891,1.891,0,0,1,3.78,0H9.806a1.406,1.406,0,0,1,1.407,1.407V13.5a1.406,1.406,0,0,1-1.407,1.407ZM3.254,9.542a.7.7,0,1,0,.5.206A.7.7,0,0,0,3.254,9.542Zm1.671.22a.264.264,0,0,0-.264.264v.44a.264.264,0,0,0,.264.264h3.21a.264.264,0,0,0,.264-.264v-.44a.264.264,0,0,0-.264-.264ZM2.872,6.6a.175.175,0,0,0-.124.052l-.373.373a.176.176,0,0,0,0,.249L3.432,8.331a.175.175,0,0,0,.124.052h.016A.175.175,0,0,0,3.7,8.331L5.53,6.5a.176.176,0,0,0,0-.249l-.373-.373a.176.176,0,0,0-.249,0L3.564,7.22,3,6.652A.175.175,0,0,0,2.872,6.6ZM6.4,6.947l-.967.967h2.7A.264.264,0,0,0,8.4,7.651v-.44a.264.264,0,0,0-.264-.264H6.4Zm-.814-5.54a.484.484,0,1,0,.342.142A.48.48,0,0,0,5.584,1.407Z',
    ],
  },
  lead: {
    width: 27,
    height: 21,
    viewBox: '0 0 27 21',
    paths: [
      'M20.3,7.9h-6.1v2.3c0,1.6-1.3,2.9-2.9,2.9s-2.9-1.3-2.9-2.9V5.3L5.7,6.9C5,7.3,4.5,8.2,4.5,9.1V11l-3.2,1.9c-0.6,0.4-0.8,1.1-0.5,1.8L4,20.1c0.4,0.6,1.1,0.8,1.8,0.5l4.2-2.4h5.5c1.4,0,2.6-1.2,2.6-2.6h0.6c0.7,0,1.3-0.6,1.3-1.3v-2.6h0.3c0.5,0,1-0.4,1-1V8.9C21.2,8.4,20.8,7.9,20.3,7.9z M26.2,6.4L23,0.9c-0.4-0.6-1.1-0.8-1.8-0.5l-4.2,2.4H13c-0.5,0-1,0.1-1.4,0.4L10.2,4C9.9,4.2,9.6,4.7,9.6,5.1v5.1c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6V6.6h7.4c1.2,0,2.3,1,2.3,2.3V10l3.2-1.9C26.3,7.8,26.5,7,26.2,6.4z',
    ],
  },
}
