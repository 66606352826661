import { ReportType } from './constants'

export const resourcesList = (list) => {
  let resource = []

  list.forEach((i) => {
    if (i.advertisements) {
      i.advertisements.forEach((j) => {
        resource.push({
          campaignId: i.campaignId,
          name: `${i.account.name} ${i.account.surname}`,
          sort: j.sort,
          report: j.reportType === ReportType.PDF ? j.report : null,
          dataStudioReport:
            j.reportType === ReportType.DataStudio ? j.report : null,
          advertisingId: j.advertisingId,
          advertisingSmartTrackingLink: j.smartTrackingLink,
          advertisingTypeName: j.advertisingType.name,
          advertisingTypeId: j.advertisingTypeId,
          resourceId: j.resources[0].resourceId,
          resourceSrc: j.resources[0].src,
          resourceStatus: j.resources[0].status,
          resourceCreated: j.resources[0].createdAt,
          resourceUpdated: j.resources[0].updatedAt,
        })
      })
    }
    if (i.adSponsoredContents) {
      i.adSponsoredContents.forEach((j) => {
        if (j.adSponsoredContentPricingAdContents) {
          j.adSponsoredContentPricingAdContents.forEach((k) => {
            resource.push({
              campaignId: i.campaignId,
              name: `${i.account.name} ${i.account.surname}`,
              sort: j.sort,
              report: j.reportType === ReportType.PDF ? j.report : null,
              dataStudioReport:
                j.reportType === ReportType.DataStudio ? j.report : null,
              advertisingId: j.adSponsoredContentId,
              advertisingSmartTrackingLink: '',
              advertisingTypeName:
                k.pricingAdContent.advertisingTypeDigitalProperties
                  .advertisingType.name,
              advertisingTypeId:
                k.pricingAdContent.advertisingTypeDigitalProperties
                  .advertisingTypeId,
              resourceId: j.resourcesContents[0].ResourceContentId,
              resourceSrc: j.resourcesContents[0].src,
              resourceStatus: j.resourcesContents[0].status,
              resourceCreated: j.resourcesContents[0].createdAt,
              resourceUpdated: j.resourcesContents[0].updateAt,
              title: j.title,
              lead: j.lead,
              paragraph: j.paragraph,
            })
          })
        }
      })
    }
  })
  resource = resource.sort((a, b) => {
    return a.campaignId - b.campaignId || a.sort - b.sort
  })
  return resource
}
