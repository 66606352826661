export const MaterialStatus = {
  Rejected: 'Rechazado',
  Approved: 'Aprobado',
  Pending: 'Pendiente',
}

export const MaterialStatusCode = {
  Rejected: 0,
  Approved: 1,
  Pending: 2,
}

export const AdvertisingTypeCode = {
  CMP: 1,
  DMP: 2,
  Content: 3,
}

export const AdvertisingType = {
  Display: 'Display',
  Content: 'Content',
}

export const ReportType = {
  PDF: 1,
  DataStudio: 2,
}

export const dateFormat = 'DD/MM/YYYY'

export const imgContent = 'img/content/zu-contenido-miniatura.jpg'
