import axios from 'axios'
import { API_ENDPOINT } from '../../../constants/apiEndpoint'

/**
 * Get Campaigns from API
 * @returns {response} response axios
 */
export const fetchAllCampaigns = () => {
  const url = `${API_ENDPOINT}/campaigns?filter={"where":{"manualStatus":{"neq":0}},"include":[{"relation":"account"},{"relation":"user"},{"relation":"adSponsoredContents","scope":{"include":[{"relation":"adSponsoredContentPricingAdContents","scope":{"include":[{"relation":"pricingAdContent","scope":{"include":[{"relation":"advertisingTypeDigitalProperties","scope":{"include":[{"relation":"digitalProperty"}]}}]}}]}}]}},{"relation":"advertisements","scope":{"order":["sort ASC"],"include":[{"relation":"advertisingType"},{"relation":"advertisingDetailSegmentations","scope":{"include":[{"relation":"segmentationScope","scope":{"include":[{"relation":"segmentationMatch"},{"relation":"formatDisplay","scope":{"include":[{"relation":"format"},{"relation":"display"}]}}]}}]}},{"relation":"advertisingDetails","scope":{"include":[{"relation":"formatBySectionOnDisplay","scope":{"include":[{"relation":"formatDisplay","scope":{"include":[{"relation":"format"},{"relation":"display"}]}},{"relation":"digitalPropertyBySection","scope":{"include":[{"relation":"digitalProperty"}]}}]}}]}}]}}]}`
  return axios.get(url).then((r) => r.data)
}

/**
 * assign selected user
 * @param {num} campaignId
 * @param {num} userId
 */
export const assignSelectedUser = (campaignId, userId) => {
  let body = {
    userId: parseInt(userId),
    campaignId: parseInt(campaignId),
  }
  return axios.post(`${API_ENDPOINT}/campaigns/assign/users`, body)
}

/**
 * cancel campaign
 * @param {num} campaignId
 */
export const cancelCampaign = (campaignId) => {
  let body = {
    manualStatus: 2,
  }
  return axios.patch(`${API_ENDPOINT}/campaigns/${campaignId}`, body)
}
