import { createAsyncThunk } from '@reduxjs/toolkit'
import * as API from './repository'
import {
  setErrorGlobalMessage,
  setSuccessGlobalMessage,
} from '../../../core/ui/ui.slice'
import { handleError } from '../../../helpers/handleError'
import messages from './../../../constants/messages'
import { selectMaterialId } from './index'
import { resourcesList } from './helpers'

export const fetchMaterials = createAsyncThunk(
  'Materials/fetchMaterials',
  async () => {
    const materialsAndCampaignsList = await API.fetchAllMaterials()
    return resourcesList(materialsAndCampaignsList)
  }
)

export const updateMaterialStatus = createAsyncThunk(
  'Materials/updateMaterialStatus',
  async ({ material, state, observation }, thunkAPI) => {
    try {
      // update material status
      await API.updateResourceStatus(
        material.resourceId,
        material.advertisingTypeId,
        state,
        observation
      )
      thunkAPI.dispatch(setSuccessGlobalMessage())
      return {
        id: selectMaterialId(material),
        changes: { resourceStatus: state },
      }
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)

export const replaceResource = createAsyncThunk(
  'Materials/replaceResource',
  async ({ advertisingId, file }, thunkAPI) => {
    try {
      await API.replaceResource(advertisingId, file)
      thunkAPI.dispatch(fetchMaterials())
      thunkAPI.dispatch(setSuccessGlobalMessage())
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)

export const uploadReport = createAsyncThunk(
  'Materials/uploadReport',
  async ({ advertisingId, advertisingTypeId, file }, thunkAPI) => {
    try {
      await API.uploadReport(advertisingId, advertisingTypeId, file)
      thunkAPI.dispatch(fetchMaterials())
      thunkAPI.dispatch(setSuccessGlobalMessage())
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error, messages.messageErrorUploadingPdf),
        })
      )
      throw error
    }
  }
)

export const updateSmartReport = createAsyncThunk(
  'Materials/updateSmartReport',
  async ({ material, urlSmart }, thunkAPI) => {
    try {
      await API.updateSmartReport(material.advertisingId, urlSmart)
      thunkAPI.dispatch(setSuccessGlobalMessage())
      return {
        id: selectMaterialId(material),
        changes: { advertisingSmartTrackingLink: urlSmart },
      }
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)

export const updateDataStudioReport = createAsyncThunk(
  'Materials/updateDataStudioReport',
  async ({ material, urlReport }, thunkAPI) => {
    try {
      await API.updateDataStudioReport(
        material.advertisingTypeId,
        material.advertisingId,
        urlReport
      )
      thunkAPI.dispatch(setSuccessGlobalMessage())
      return {
        id: selectMaterialId(material),
        changes: { dataStudioReport: urlReport },
      }
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)
