import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  fetchMaterials,
  updateDataStudioReport,
  updateMaterialStatus,
  updateSmartReport,
} from './thunk'

export const selectMaterialId = ({ campaignId, sort }) =>
  `${campaignId}-${sort}`

const materialsAdapter = createEntityAdapter({
  selectId: selectMaterialId,
  sortComparer: false,
})

const slice = createSlice({
  name: 'Materials',
  initialState: materialsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMaterials.fulfilled, materialsAdapter.setAll)
    builder.addCase(updateMaterialStatus.fulfilled, materialsAdapter.updateOne)
    builder.addCase(updateSmartReport.fulfilled, materialsAdapter.updateOne)
    builder.addCase(
      updateDataStudioReport.fulfilled,
      materialsAdapter.updateOne
    )
  },
})

export const { setError } = slice.actions

export const {
  selectAll: getAllMaterials,
  selectById: getMaterialById,
  selectIds: getAllMaterialsIds,
  selectEntities: getAllMaterialEntities,
} = materialsAdapter.getSelectors((state) => state[slice.name])

export default slice.reducer
