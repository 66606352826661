import styled from 'styled-components'

export const Path = styled.path`
  fill: ${({ theme, fill }) =>
    fill === 'white'
      ? theme.common.white
      : fill === 'primary'
      ? theme.palette.primary.main
      : fill};
`

export const SVG = styled.svg`
  margin-right: ${(props) => (props.mr ? `${props.mr}px` : null)};
  margin-left: ${(props) => (props.ml ? `${props.ml}px` : null)};
`
